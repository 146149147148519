module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"EnviMAP Tető","short_name":"EnviMAP","start_url":"/teto.envimap.hu","background_color":"#00FF7F","display":"minimal-ui","icon":"src/images/enviMAP_favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"89c0acb099cc9cecaf6570357cacf92e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 0px)","sm":"(max-width: 378px)","md":"(max-width: 672px)","lg":"(max-width: 980px)","xl":"(max-width: 1200px)","xxl":"(max-width: 1568px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-F3MNGRZGCR"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
